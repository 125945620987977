import { useState, useEffect, useContext } from "react";
import {
    Container,
    Grid,
    Paper,
    Typography,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Button,
    Box,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { Context } from "../../context/Context";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import useGlobalStyle from "../../themes/globalStyle";

const initialFileTypes = {
    iso: {
        title: "ISO",
        menuItems: [
            { value: "esxi", label: "ESXi" },
            { value: "vcsa", label: "VCSA" },
            { value: "vm", label: "VM" },
        ],
    },
    template: {
        title: "Template",
        menuItems: [],
    },
    license: {
        title: "License",
        menuItems: [
            { value: "esxi", label: "ESXi" },
            { value: "vcsa", label: "VCSA" },
            { value: "vsan", label: "vSAN" },
        ],
    },
    ova: {
        title: "OVA",
    },
    collection: {
        title: "Collection",
        menuItems: [],
    },
    playbook: {
        title: "Playbook",
        menuItems: [],
    },
    offlinepackages: {
        title: "Offline Packages",
        menuItems: [],
    },
};

export const UploadField = (props) => {
    const [fileTypes, setFileTypes] = useState(initialFileTypes);
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("No file chosen.");
    const [fileUpload, setFileUpload] = useState("");
    const [licenseKey, setLicenseKey] = useState("");
    const [wait, setWait] = useState(false);
    const [status, setStatus] = useState();
    const [progress, setProgress] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [validKeyFormat, setValidKeyFormat] = useState(false);
    const { systemStateSetters } = useSystemSettingsContext();

    const token = useContext(Context);

    const globalStyle = useGlobalStyle();

    let uploadISOStreamURL = "";
    let uploadLicenseURL = "";
    let uploadOVAStreamURL = "";
    let getDevicesURL = "";
    let uploadTemplateURL = "";
    let uploadCollectionURL = "";
    let uploadPlaybookURL = "";
    let uploadOfflinePackageURL = "";
    if (process.env.NODE_ENV === "development") {
        uploadISOStreamURL = "http://localhost:80/api/isos/upload-iso-stream";
        uploadLicenseURL = "http://localhost:80/api/licenses/create-license";
        uploadOVAStreamURL = "http://localhost:80/api/ovas/upload-ova-stream";
        getDevicesURL = "http://localhost:80/api/serial-devices/serial-devices";
        uploadTemplateURL =
            "http://localhost:80/api/serial-devices/upload-template";
        uploadCollectionURL =
            "http://localhost:80/api/collections/upload-collection";
        uploadPlaybookURL = "http://localhost:80/api/playbooks/upload-playbook";
        uploadOfflinePackageURL =
            "http://localhost:80/api/offline-packages/upload-offline-package";
    } else if (process.env.NODE_ENV === "production") {
        uploadISOStreamURL = "/api/isos/upload-iso-stream";
        uploadLicenseURL = "/api/licenses/create-license";
        uploadOVAStreamURL = "/api/ovas/upload-ova-stream";
        getDevicesURL = "/api/serial-devices/serial-devices";
        uploadTemplateURL = "/api/serial-devices/upload-template";
        uploadCollectionURL = "/api/collections/upload-collection";
        uploadPlaybookURL = "/api/playbooks/upload-playbook";
        uploadOfflinePackageURL =
            "/api/offline-packages/upload-offline-package";
    }

    /*
        Template Function Section
    */
    const getDevices = async () => {
        try {
            const response = await fetch(getDevicesURL, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not okay!");
            }
            const deviceData = await response.json();

            const newMenuItems = deviceData.devices.map((device) => ({
                value: `${device.name}`,
                label: `${device.vendor} ${device.model} - ${device.type}`,
            }));

            setFileTypes((prevFileTypes) => ({
                ...prevFileTypes,
                template: {
                    ...prevFileTypes.template,
                    menuItems: newMenuItems,
                },
            }));
        } catch (error) {
            console.error("Error Retrieving Devices", error);
        }
    };

    const submitData = (e, url, headers, data = {}) => {
        e.preventDefault();
        axios
            .post(url, data, {
                headers: headers,
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round(
                        (ProgressEvent.loaded / ProgressEvent.total) *
                            100 *
                            0.95
                    );
                    setProgress(progress);
                },
            })
            .then((res) => {
                setStatus(res.status);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data?.detail);
                    alert(error.response.data?.detail);
                } else if (error.request) {
                    console.log(error.request);
                    alert("Error with request. See logs.", error);
                } else {
                    alert("Error. Please contact NTS", error);
                }
            })
            .finally(() => {
                setWait(false);
                setSubmitDisabled(false);
                systemStateSetters.setIsUploading(false);
                props.onUploadSuccess(); // used to update table
            });
    };

    const handleLicenseKeyChange = (event) => {
        setLicenseKey(event.target.value);
        setStatus(undefined);
        if (
            event.target.value.match("^([A-Za-z0-9]{5}-){4}[A-Za-z0-9]{5}$") !==
            null
        ) {
            setValidKeyFormat(true);
        } else {
            setValidKeyFormat(false);
        }
    };

    const progressHandler = (event) => {
        const file = event.target.files[0];
        if (!file) {
            alert("No file selected!");
            return;
        }
        /* 
            File upload type validation
        */
        if (props.type === "iso") {
            if (
                file.type !== "application/x-iso-image" &&
                !file.name.endsWith(".iso")
            ) {
                alert("The selected file is not an ISO file.");
                return;
            }
        }
        setProgress(0);
        setFileUpload(file);
        setSelectedFileName(file.name);
    };

    const resetForm = () => {
        setFileType("");
        setFileName("");
        setLicenseKey("");
        setSelectedFileName("No file chosen.");
        /*
            Reset input file. Due to browser caching react needs this reset or it won't see the change between pages (iso, ova, ect..)
        */
        if (document.getElementById("file-input")) {
            document.getElementById("file-input").value = "";
        }
    };

    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);
        setWait(false);
        setStatus(undefined);
    };

    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
        setWait(false);
        setStatus(undefined);
    };

    const handleSubmit = (event) => {
        systemStateSetters.setIsUploading(true);
        setSubmitDisabled(true);
        setWait(true);
        let headers = {
            Authorization: `Bearer ${token.token}`,
        };
        const sendData = new FormData();
        switch (props.type) {
            case "iso":
                headers["filename"] = fileUpload?.name;
                submitData(
                    event,
                    uploadISOStreamURL +
                        `?iso_type=${fileType}&version=${fileName}`,
                    headers,
                    fileUpload
                );
                break;
            case "license":
                headers["accept"] = "application/json";
                submitData(
                    event,
                    uploadLicenseURL +
                        `?license_name=${fileName}&license_type=${fileType}&license_key=${licenseKey}`,
                    headers
                );
                break;
            case "ova":
                headers["filename"] = fileUpload?.name;
                submitData(event, uploadOVAStreamURL, headers, fileUpload);
                break;
            case "template":
                sendData.append("jinja_file", fileUpload, fileUpload?.name);
                headers["Content-Type"] = "multipart/form-data";
                submitData(
                    event,
                    uploadTemplateURL +
                        `?device_name=${fileType}&template_name=${fileName}`,
                    headers,
                    sendData
                );
                break;
            case "collection":
                sendData.append("collection", fileUpload, fileUpload?.name);
                headers["Content-Type"] = "multipart/form-data";
                submitData(event, uploadCollectionURL, headers, sendData);
                break;
            case "playbook":
                sendData.append("playbook", fileUpload, fileUpload?.name);
                headers["Content-Type"] = "multipart/form-data";
                submitData(event, uploadPlaybookURL, headers, sendData);
                break;
            case "offlinepackages":
                sendData.append(
                    "offline_package",
                    fileUpload,
                    fileUpload?.name
                );
                headers["Content-Type"] = "multipart/form-data";
                submitData(event, uploadOfflinePackageURL, headers, sendData);
                break;
            default:
                console.log("props.type not matched.");
        }
    };
    const checkISOFields = () => {
        return fileType && fileName && selectedFileName !== "No file chosen.";
    };
    const checkOVAFields = () => {
        return selectedFileName !== "No file chosen.";
    };
    const checkLicenseFields = () => {
        return fileType && fileName && validKeyFormat;
    };
    const handleDisableUpload = () => {
        switch (props.type) {
            case "iso":
                setSubmitDisabled(!checkISOFields());
                break;
            case "template":
                setSubmitDisabled(!checkISOFields());
                break;
            case "ova":
                setSubmitDisabled(!checkOVAFields());
                break;
            case "license":
                setSubmitDisabled(!checkLicenseFields());
                break;
            case "collection":
                setSubmitDisabled(!checkOVAFields());
                break;
            case "playbook":
                setSubmitDisabled(!checkOVAFields());
                break;
            case "offlinepackages":
                setSubmitDisabled(!checkOVAFields());
                break;
            default:
                setSubmitDisabled(true);
        }
    };

    // get the title and menu items for the current type
    const currentType = fileTypes[props.type];
    useEffect(() => {
        if (props.type === "template") {
            getDevices();
        }
        setFileType("");
        resetForm();
        setWait(false);
        setStatus(undefined);
    }, [props.type]);

    useEffect(() => {
        handleDisableUpload();
    }, [fileType, fileName, selectedFileName, validKeyFormat]);

    return (
        <Container maxWidth="false">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Typography
                            className={globalStyle.paperTitle}
                            variant="h5"
                        >
                            {currentType.title}
                        </Typography>
                        <Box padding={2}>
                            <FormControl fullWidth>
                                {["iso", "template", "license"].includes(
                                    props.type
                                ) && (
                                    <Box mb={2}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item xs={12} sm={2}>
                                                <Box paddingRight={0}>
                                                    <Select
                                                        value={fileType}
                                                        onChange={
                                                            handleFileTypeChange
                                                        }
                                                        displayEmpty
                                                        fullWidth
                                                        variant="outlined"
                                                        inputProps={{
                                                            "aria-label":
                                                                "Without label",
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value=""
                                                            disabled
                                                        >
                                                            Type
                                                        </MenuItem>
                                                        {currentType.menuItems.map(
                                                            (menuItem) => (
                                                                <MenuItem
                                                                    key={
                                                                        menuItem.value
                                                                    }
                                                                    value={
                                                                        menuItem.value
                                                                    }
                                                                >
                                                                    {
                                                                        menuItem.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={10}>
                                                <Box paddingRight={0}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Name"
                                                        value={fileName}
                                                        onChange={
                                                            handleFileNameChange
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {props.type === "license" && (
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Key (Format: 11111-AAAAA-22222-BBBBB-33333)"
                                                    value={licenseKey}
                                                    onChange={(e) =>
                                                        handleLicenseKeyChange(
                                                            e
                                                        )
                                                    }
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                <Box mt={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        {props.type !== "license" && (
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    color="secondary"
                                                >
                                                    Choose file...
                                                    <input
                                                        type="file"
                                                        accept={
                                                            props.type === "iso"
                                                                ? ".iso"
                                                                : props.type ===
                                                                  "ova"
                                                                ? ".ova"
                                                                : ""
                                                        }
                                                        id="file-input"
                                                        required
                                                        onChange={
                                                            progressHandler
                                                        }
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    />
                                                </Button>
                                                <Typography
                                                    variant="h7"
                                                    className={
                                                        globalStyle.rightLabel
                                                    }
                                                >
                                                    {selectedFileName}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={12}
                                            sm={
                                                props.type === "license"
                                                    ? 12
                                                    : 6
                                            }
                                            className={globalStyle.alignRight}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                                disabled={submitDisabled}
                                                onClick={handleSubmit}
                                                className={
                                                    globalStyle.buildButtons
                                                }
                                            >
                                                Upload
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        className={globalStyle.alert}
                                    >
                                        <Grid item xs={12}>
                                            {wait ? (
                                                <Alert
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                    severity="warning"
                                                >
                                                    Please wait
                                                </Alert>
                                            ) : status === 200 ? (
                                                <Alert
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                    severity="success"
                                                >
                                                    Uploaded Successfully
                                                </Alert>
                                            ) : (
                                                ""
                                            )}

                                            {wait ? (
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            ) : status === 200 ? (
                                                ""
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UploadField;
