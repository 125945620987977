import {
    Container,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    CircularProgress,
    TablePagination,
} from "@material-ui/core";
import { Context } from "../../context/Context";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import useGlobalStyle from "../../themes/globalStyle";

const UploadTable = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const token = useContext(Context);
    const globalStyle = useGlobalStyle();

    const typeToKeys = {
        iso: ["iso_type", "iso_filename", "version"],
        license: ["license_name", "license_type", "license_key"],
        ova: ["name", "filename"],
        template: ["template_name", "file_name", "device_name"],
        collection: ["name", "version"],
        playbook: ["name", "filename"],
        offlinepackages: ["filename"],
    };
    const tLables = {
        iso: ["Type", "Filename", "Version"],
        license: ["Name", "Type", "Key"],
        ova: ["Name", "Filename"],
        template: ["Template Name", "Filename", "Device Name"],
        collection: ["Name", "Version"],
        playbook: ["Name", "Filename"],
        offlinepackages: ["Filename"],
    };
    const typeToDataKey = {
        iso: "isos",
        license: "licenses",
        ova: "ovas",
        template: "templates",
        collection: "collections",
        playbook: "playbooks",
        offlinepackages: "offlinePackages",
    };
    const keys = typeToKeys[props.type] || [];
    const typeLables = tLables[props.type] || [];
    const dataKey = typeToDataKey[props.type] || null;

    let isoUrl = "";
    let deleteIsoUrl = "";
    let getAllOVAS = "";
    let deleteOVAURL = "";
    let deleteLicenseURL = "";
    let licenseURL = "";
    let templateURL = "";
    let deleteTemplateURL = "";
    let collectionURL = "";
    let deleteCollectionURL = "";
    let playbookURL = "";
    let deletePlaybookURL = "";
    let offlinePackagesURL = "";
    let deleteOfflinePackageURL = "";
    if (process.env.NODE_ENV === "development") {
        isoUrl = "http://localhost:80/api/isos/isos";
        deleteIsoUrl = "http://localhost:80/api/isos/iso";
        getAllOVAS = "http://localhost:80/api/ovas/ovas";
        deleteOVAURL = "http://localhost:80/api/ovas";
        deleteLicenseURL = "http://localhost:80/api/licenses/license";
        licenseURL = "http://localhost:80/api/licenses/";
        templateURL = "http://localhost:80/api/serial-devices/templates";
        deleteTemplateURL = "http://localhost:80/api/serial-devices/template";
        collectionURL = "http://localhost:80/api/collections/collections";
        deleteCollectionURL = "http://localhost:80/api/collections/collection";
        playbookURL = "http://localhost:80/api/playbooks/playbooks";
        deletePlaybookURL = "http://localhost:80/api/playbooks/playbook";
        offlinePackagesURL =
            "http://localhost:80/api/offline-packages/offline-packages";
        deleteOfflinePackageURL =
            "http://localhost:80/api/offline-packages/offline-package";
    } else if (process.env.NODE_ENV === "production") {
        isoUrl = "/api/isos/isos";
        deleteIsoUrl = "/api/isos/iso";
        getAllOVAS = "/api/ovas/ovas";
        deleteOVAURL = "/api/ovas";
        deleteLicenseURL = "/api/licenses/license";
        licenseURL = "/api/licenses/";
        templateURL = "/api/serial-devices/templates";
        deleteTemplateURL = "/api/serial-devices/template";
        collectionURL = "/api/collections/collections";
        deleteCollectionURL = "/api/collections/collection";
        playbookURL = "/api/playbooks/playbooks";
        deletePlaybookURL = "/api/playbooks/playbook";
        offlinePackagesURL = "/api/offline-packages/offline-packages";
        deleteOfflinePackageURL = "/api/offline-packages/offline-package";
    }

    const fetchData = async (url) => {
        await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setData(resdata);
            })
            .catch((error) => {
                alert("Error Retrieving Data", error);
            });
    };
    const deleteData = async (url) => {
        console.log(url);
        await fetch(url, {
            method: "Delete",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const handleDelete = (item) => {
        try {
            switch (props.type) {
                case "iso":
                    deleteData(
                        deleteIsoUrl +
                            "/" +
                            item.version +
                            "?iso_type=" +
                            item.iso_type
                    );
                    break;
                case "license":
                    deleteData(
                        deleteLicenseURL +
                            "/" +
                            item.license_name +
                            "?license_name=" +
                            item.license_name
                    );
                    break;
                case "ova":
                    deleteData(deleteOVAURL + "/" + item.filename);
                    break;
                case "template":
                    deleteData(deleteTemplateURL + "/" + item.template_name);
                    break;
                case "collection":
                    deleteData(deleteCollectionURL + "/" + item.name);
                    break;
                case "playbook":
                    deleteData(deletePlaybookURL + "/" + item.name);
                    break;
                case "offlinepackages":
                    deleteData(deleteOfflinePackageURL + "/" + item.filename);
                    break;
                default:
                    console.log("props.type not matched");
            }
            props.onUploadSuccess();
        } catch (error) {
            console.error("Error Deleting:", error);
        }
    };

    const getData = () => {
        switch (props.type) {
            case "iso":
                fetchData(isoUrl);
                break;
            case "ova":
                fetchData(getAllOVAS);
                break;
            case "license":
                fetchData(licenseURL);
                break;
            case "template":
                fetchData(templateURL);
                break;
            case "collection":
                fetchData(collectionURL);
                break;
            case "playbook":
                fetchData(playbookURL);
                break;
            case "offlinepackages":
                fetchData(offlinePackagesURL);
                break;
            default:
                console.log("type not set");
        }
    };

    /*
        Pagination Functions Section
    */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };
    const dataItems = data[dataKey] || [];
    const slicedData = dataItems.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    useEffect(() => {
        getData();
        setPage(0); // update page if type change
    }, [props.type, props.dataChanged]);
    // console.log(data)

    return (
        <Container maxWidth="false">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="my table" size="small">
                            <TableHead>
                                <TableRow>
                                    {typeLables.map((t) => (
                                        <TableCell key={t}>{t}</TableCell>
                                    ))}
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell
                                            colSpan={keys.length + 1}
                                            style={{ textAlign: "center" }}
                                        >
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : slicedData && slicedData.length > 0 ? (
                                    slicedData.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            className={
                                                index % 2 === 0
                                                    ? globalStyle.tableRowEven
                                                    : globalStyle.tableRowOdd
                                            }
                                        >
                                            {keys.map((key) =>
                                                [
                                                    "template",
                                                    "playbook",
                                                    "collection",
                                                ].includes(props.type) ? (
                                                    <TableCell
                                                        className={
                                                            globalStyle.linkNoUnderline
                                                        }
                                                        key={`${index}_${key}`}
                                                        scope="row"
                                                        component={Link}
                                                        to={{
                                                            pathname:
                                                                props.type ===
                                                                "template"
                                                                    ? `/uploadpage/template/${item.template_name}`
                                                                    : props.type ===
                                                                      "collection"
                                                                    ? `/uploadpage/collection/${item?.name}`
                                                                    : `/uploadpage/playbook/${item?.name}`,
                                                        }}
                                                    >
                                                        {item[key]}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        key={`${index}_${key}`}
                                                        className={
                                                            globalStyle.cursorDefault
                                                        }
                                                    >
                                                        {item[key]}
                                                    </TableCell>
                                                )
                                            )}
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                `Are you sure you wish to delete this ${props.type}?`
                                                            )
                                                        )
                                                            handleDelete(item);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={keys.length + 1}
                                            style={{ textAlign: "center" }}
                                        >
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data[dataKey]?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UploadTable;
