import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from "./context/Context";
import { SystemSettingsProvider } from "./context/SystemSettings";
import { createGlobalTheme } from "./themes/globalTheme";

ReactDOM.render(
    <MuiThemeProvider theme={createGlobalTheme()}>
        <ContextProvider>
            <SystemSettingsProvider>
                <App />
            </SystemSettingsProvider>
        </ContextProvider>
    </MuiThemeProvider>,
    document.getElementById("root")
);
