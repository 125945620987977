import { DevTool } from "@hookform/devtools";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Context } from "../../../context/Context";

import {
    Card,
    Container,
    Fab,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useGlobalStyle from "../../../themes/globalStyle";
import Alerttip from "../../alertTip/Alerttip";
import useStyles from "./styles";

const DEFAULT_VALUES = [
    "MGMT",
    "Management0-0",
    "Primary",
    "Aux",
    "LAN",
    "WAN",
];

const NetworkConfig = ({
    register,
    unregister,
    controls,
    info,
    getValues,
    setValue,
    watch,
}) => {
    const [allTemplates, setAllTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [serialPorts, setSerialPorts] = useState();
    const [ovas, setOVAS] = useState([]);
    const [deployOpts, setDeployOpts] = useState([]);
    const [allDevices, setAllDevices] = useState([]);
    const [device, setDevice] = useState({});
    const [portCounter, setPortCounter] = useState(0);
    const [interfaceCounter, setInterfaceCounter] = useState(0);
    const token = useContext(Context);
    const classes = useStyles();
    const globalStyle = useGlobalStyle();

    const { fields, append, remove } = useFieldArray({
        control: controls,
        name: "network_devices.0.port_mapping",
    });

    let getSerialDevices = "";
    let getSerialTemplates = "";
    let getSerialPorts = "";
    let getAllOVAS = "";
    if (process.env.NODE_ENV === "development") {
        getSerialDevices =
            "http://localhost:80/api/serial-devices/serial-devices";
        getSerialTemplates = "http://localhost:80/api/serial-devices/templates";
        getSerialPorts = "http://localhost:80/api/serial-ports/serial_ports";
        getAllOVAS = "http://localhost:80/api/ovas/ovas";
    } else if (process.env.NODE_ENV === "production") {
        getSerialDevices = "/api/serial-devices/serial-devices";
        getSerialTemplates = "/api/serial-devices/templates";
        getSerialPorts = "/api/serial-ports/serial_ports";
        getAllOVAS = "/api/ovas/ovas";
    }

    // Values for port mapping
    const PA_VM_INTERFACES =
        allDevices.find((device) => device.name === "Palo Alto VM-ESX")
            ?.network_schema ?? [];
    const ASAV_INTERFACES =
        allDevices.find((device) => device.name === "Cisco ASAv")
            ?.network_schema ?? [];
    const CSR1000V_INTERFACES =
        allDevices.find((device) => device.name === "Cisco CSR 1000V")
            ?.network_schema ?? [];
    const ARUBAOS_VMC_INTERFACES =
        allDevices.find((device) => device.name === "Aruba VMC")
            ?.network_schema ?? [];
    const RIVERBED_INTERFACES =
        allDevices
            .find((device) => device.name === "Riverbed SteelHead")
            ?.network_schema.slice(0, -1) ?? [];
    const COMPASS_INTERFACES =
        allDevices
            .find((device) => device.name === "Vidterra Compass")
            ?.network_schema.slice(0, -1) ?? [];
    const DEFAULT_INTERFACES = ["Network Adapter {1}"];

    const interfaceMapping = {
        "Palo Alto VM-ESX": PA_VM_INTERFACES,
        "Cisco ASAv": ASAV_INTERFACES,
        "Cisco CSR 1000V": CSR1000V_INTERFACES,
        "Aruba VMC": ARUBAOS_VMC_INTERFACES,
        "Riverbed SteelHead": RIVERBED_INTERFACES,
        "Vidterra Compass": COMPASS_INTERFACES,
    };

    const getTemplates = async () => {
        await fetch(getSerialTemplates, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAllTemplates(data.templates);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getDevices = async () => {
        await fetch(getSerialDevices, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAllDevices(data.devices);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAllSerialPorts = async () => {
        await fetch(getSerialPorts, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((serialData) => {
                setSerialPorts(serialData.serial_ports);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getOVAS = async () => {
        await fetch(getAllOVAS, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setOVAS(resdata.ovas);
            })
            .catch((error) => {
                alert("Error Retrieving ISOs", error);
            });
    };

    const handleDeviceChange = (dv) => {
        unregister(`network_devices`);
        register(`network_devices.0.name`);
        setValue(`network_devices.0.name`, dv.name);
        setDevice(dv);
        if (Array.isArray(dv.required_fields)) {
            for (let [index, rf] of dv.required_fields.entries()) {
                setValue(
                    `network_devices.0.config_variables.${index}.name`,
                    rf
                );
                setValue(
                    `network_devices.0.config_variables.${index}.value`,
                    ""
                );
            }
        }
    };
    const handleVariationChange = (dv, device) => {
        unregister(`network_devices.0.config_variables`);
        for (let [index, rf] of device.required_fields[dv].entries()) {
            setValue(`network_devices.0.config_variables.${index}.name`, rf);
            setValue(`network_devices.0.config_variables.${index}.value`, "");
        }
    };
    const handleOVAChange = (ova) => {
        setDeployOpts(ova.deployment_options);
        setInterfaces();
        setValue(`network_devices.0.ova_filename`, ova.filename);
        unregister(`network_devices.0.deployment_option`);
    };
    const handleUpdateTemplate = (template) => {
        setSelectedTemplate(template);
        setValue(`network_devices.0.template_name`, template.template_name);
        unregister(`network_devices.0.config_variables`);
        for (let [index, cv] of template.config_variables.entries()) {
            setValue(
                `network_devices.0.config_variables.${index}.name`,
                cv.name
            );
            setValue(`network_devices.0.config_variables.${index}.value`, "");
        }
    };
    const handleDelete = (id) => {
        remove(id);
        setPortCounter((prevCounter) => prevCounter - 1);
        setInterfaceCounter((prevCounter) => prevCounter - 1);
    };

    const handleAddFields = () => {
        let interfaceList = [];
        if (device.name in interfaceMapping) {
            interfaceList = interfaceMapping[device.name];
        } else {
            interfaceList = DEFAULT_INTERFACES;
        }

        let defaultInterfaceValue;
        if (portCounter < interfaceList.length) {
            defaultInterfaceValue = interfaceList[portCounter];
        } else {
            const lastPattern = interfaceList[interfaceList.length - 1];
            defaultInterfaceValue = lastPattern;
        }

        defaultInterfaceValue = defaultInterfaceValue.replace(
            /\{(\d+)\}/g,
            () => interfaceCounter
        );

        append({
            interface: defaultInterfaceValue,
            port_group: "",
        });

        setPortCounter((prevCounter) => prevCounter + 1);
        setInterfaceCounter((prevCounter) => prevCounter + 1);
    };

    const setInterfaces = () => {
        let map = [];

        if (device.name in interfaceMapping) {
            map = interfaceMapping[device.name];
            setPortCounters();
        } else {
            map = DEFAULT_INTERFACES;
            setPortCounter(1);
            setInterfaceCounter(1);
        }

        const interfaceNames = map.map((template, index) => {
            if (template.includes("{0}")) {
                let result = template.replace("{0}", "0");
                setInterfaceCounter(1);
                return result;
            } else if (template.includes("{1}")) {
                let result = template.replace("{1}", "1");
                setInterfaceCounter(2);
                return result;
            }
            return template;
        });

        for (let i = fields.length - 1; i >= 0; i--) {
            remove(i);
        }
        // append default interfaces to fields array
        interfaceNames.forEach((interfaceName) => {
            append({ interface: interfaceName, port_group: "" });
        });
    };

    const setPortCounters = (dv = device) => {
        // Set port counters
        let interfaces = [];
        if ("port_mapping" in dv) {
            interfaces = dv.port_mapping;
        } else {
            interfaces = dv.network_schema;
        }
        const interfacesLen = interfaces.length;
        setPortCounter(interfacesLen);
        const lastInt = interfaces?.[interfacesLen - 1]?.interface;
        const lastInterfaceCount = lastInt?.slice(-1);
        setInterfaceCounter(
            isNaN(lastInterfaceCount) ? 1 : parseInt(lastInterfaceCount) + 1
        );
    };

    // Don't add port groups that aren't available
    let vmPortGroup = getValues("vm_portgroup") || "";
    let addedVSwitches = getValues("added_vswitches") || [];
    function getPortGroups() {
        let pgList = [vmPortGroup];
        for (const vs of addedVSwitches) {
            for (const pgs of vs.port_groups) {
                pgList.push(pgs.name);
            }
        }
        return pgList;
    }
    const portGroups = getPortGroups();
    const portMapping = getValues(`network_devices.0.port_mapping`) || [];
    portMapping?.forEach(function (pm, i) {
        if (!portGroups.includes(pm?.port_group)) {
            setValue(`network_devices.0.port_mapping.${i}.port_group`, "");
        }
    });

    const deviceTemplates = useMemo(
        () => allTemplates.filter((item) => item.device_name === device.name),
        [device, allTemplates]
    );

    useEffect(() => {
        getTemplates();
        getDevices();
        getAllSerialPorts();
        getOVAS();
        getPortGroups();
    }, []);

    // From previous else from useForm data (switching tabs)
    useEffect(() => {
        const prevDevice = info?.virtual_serial_devices?.[0];
        let formDevice = getValues("network_devices.[0]");

        const trySetDevice = (dv) => {
            if (!dv) {
                return false;
            }
            const filteredDevice = allDevices.filter(
                (item) => item.name === dv.name
            );
            if (filteredDevice.length > 0) {
                setDevice(filteredDevice[0]);
                setPortCounters(dv);
                return true;
            }
            return false;
        };
        for (const dv of [formDevice, prevDevice]) {
            if (trySetDevice(dv)) return;
        }
    }, [allDevices]);

    useEffect(() => {
        const defaultTemplate =
            info?.virtual_serial_devices?.[0]?.template_name;
        if (defaultTemplate) {
            setValue(`network_devices.0.template_name`, defaultTemplate);
        }
    }, []);

    const uniquePort = [];

    serialPorts?.map((p) =>
        p.enabled === true ? uniquePort.push(p.port) : ""
    );

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
            component="main"
            maxWidth="xl"
        >
            <Typography component="h1" variant="h5">
                Build a New Virtual Network
            </Typography>
            <div className={classes.displayFlex}>
                <TextField
                    select
                    className={globalStyle.customTextField}
                    variant="outlined"
                    control={controls}
                    label="Device"
                    {...register(`network_devices.0.name`)}
                    value={watch(`network_devices.0.name`) || ""}
                    style={{ marginTop: "20px" }}
                >
                    {allDevices.map(
                        (d) =>
                            [
                                "Cisco CSR 1000V",
                                "Cisco ASAv",
                                "Palo Alto VM-ESX",
                                "Aruba VMC",
                                "Riverbed SteelHead",
                                "Vidterra Compass",
                                "Basic OVA",
                            ].includes(d.name) && (
                                <MenuItem
                                    onClick={(e) => {
                                        handleDeviceChange(d);
                                    }}
                                    value={d.name}
                                    key={d.name}
                                >
                                    {d.name}
                                </MenuItem>
                            )
                    )}
                </TextField>
            </div>
            {device.name && (
                <form
                    autoComplete="off"
                    className={classes.form}
                    style={{ marginBottom: "-90px" }}
                >
                    <Card
                        className={classes.card}
                        style={{
                            marginBottom: "30px",
                            width: "100%",
                            boxShadow: "none",
                        }}
                        spacing={2}
                        name="network_devices"
                    >
                        <Grid container spacing={2}>
                            {device.name === "Basic OVA" ? null : [
                                  "Aruba VMC",
                                  "Riverbed SteelHead",
                                  "Palo Alto VM-ESX",
                                  "Vidterra Compass"
                              ].includes(device.name) ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                >
                                    <Card className={classes.card}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            className={
                                                globalStyle.customCardTitle
                                            }
                                        >
                                            Required Fields
                                        </Typography>
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {device?.variations && (
                                                <TextField
                                                    select
                                                    className={
                                                        globalStyle.customTextField
                                                    }
                                                    variant="outlined"
                                                    control={controls}
                                                    label="Device Variation"
                                                    {...register(
                                                        `network_devices.0.variation`
                                                    )}
                                                    value={
                                                        watch(
                                                            `network_devices.0.variation`
                                                        ) || ""
                                                    }
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    {device?.variations.map(
                                                        (dv) => (
                                                            <MenuItem
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleVariationChange(
                                                                        dv,
                                                                        device
                                                                    );
                                                                }}
                                                                value={dv}
                                                                key={dv}
                                                            >
                                                                {dv}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {watch(
                                                `network_devices.0.config_variables`
                                            )?.map((con, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            display:
                                                                "inline-flex",
                                                            margin: "5px 0",
                                                            width: "230px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "none",
                                                            }}
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                value={
                                                                    con?.name
                                                                }
                                                                {...register(
                                                                    `network_devices.0.config_variables[${i}].name`
                                                                )}
                                                            />
                                                        </div>
                                                        {con?.name?.length >
                                                        17 ? (
                                                            <Alerttip
                                                                title={con?.name?.toUpperCase()}
                                                            >
                                                                <TextField
                                                                    variant="outlined"
                                                                    label={
                                                                        con?.name
                                                                            ?.toUpperCase()
                                                                            .slice(
                                                                                0,
                                                                                16
                                                                            ) +
                                                                        "..."
                                                                    }
                                                                    {...register(
                                                                        `network_devices.0.config_variables[${i}].value`
                                                                    )}
                                                                />
                                                            </Alerttip>
                                                        ) : (
                                                            <TextField
                                                                variant="outlined"
                                                                label={con?.name?.toUpperCase()}
                                                                {...register(
                                                                    `network_devices.0.config_variables[${i}].value`
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Card>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                >
                                    <Card className={classes.card}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            className={
                                                globalStyle.customCardTitle
                                            }
                                        >
                                            Template
                                        </Typography>
                                        <div className={classes.displayFlex}>
                                            <InputLabel
                                                className={
                                                    globalStyle.customInputLabelForm
                                                }
                                            >
                                                Device Name:{" "}
                                                {watch(
                                                    `network_devices.0.name`
                                                ) || "Not selected"}
                                            </InputLabel>
                                        </div>
                                        <div className={classes.displayFlex}>
                                            <TextField
                                                select
                                                className={
                                                    globalStyle.customTextField
                                                }
                                                variant="outlined"
                                                control={controls}
                                                label="Template"
                                                {...register(
                                                    `network_devices.0.template_name`
                                                )}
                                                value={watch(
                                                    `network_devices.0.template_name`
                                                )}
                                                onChange={(e) => {
                                                    const selectedTemplate =
                                                        deviceTemplates.find(
                                                            (t) =>
                                                                t.template_name ===
                                                                e.target.value
                                                        );
                                                    if (selectedTemplate) {
                                                        handleUpdateTemplate(
                                                            selectedTemplate
                                                        );
                                                    }
                                                }}
                                            >
                                                {deviceTemplates.map((t, i) => (
                                                    <MenuItem
                                                        value={t?.template_name}
                                                        key={t?.template_name}
                                                    >
                                                        {t?.template_name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.displayFlex}>
                                            <TextField
                                                select
                                                className={
                                                    globalStyle.customTextField
                                                }
                                                variant="outlined"
                                                control={controls}
                                                label="Port"
                                                {...register(
                                                    `network_devices.0.port`
                                                )}
                                                value={
                                                    watch(
                                                        `network_devices.0.port`
                                                    ) || ""
                                                }
                                            >
                                                {[
                                                    "None",
                                                    "/dev/char/serial/uart0",
                                                    "dev/char/serial/uart1",
                                                ].map((port) => (
                                                    <MenuItem
                                                        value={port === "None" ? "" : port}
                                                        key={port}
                                                    >
                                                        {port}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {watch(
                                                `network_devices.0.config_variables`
                                            )?.length > 0 && (
                                                <>
                                                    <Typography
                                                        component="h4"
                                                        variant="h6"
                                                        className={
                                                            globalStyle.customCardTitle
                                                        }
                                                    >
                                                        Template Variables
                                                    </Typography>

                                                    {watch(
                                                        `network_devices.0.config_variables`
                                                    )?.map((con, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                style={{
                                                                    display:
                                                                        "inline-flex",
                                                                    margin: "5px 0",
                                                                    width: "230px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={
                                                                            con?.name
                                                                        }
                                                                        {...register(
                                                                            `network_devices.0.config_variables[${i}].name`
                                                                        )}
                                                                    />
                                                                </div>
                                                                {con?.name
                                                                    .length >
                                                                17 ? (
                                                                    <Alerttip
                                                                        title={con?.name.toUpperCase()}
                                                                    >
                                                                        <TextField
                                                                            variant="outlined"
                                                                            label={
                                                                                con?.name
                                                                                    .toUpperCase()
                                                                                    .slice(
                                                                                        0,
                                                                                        16
                                                                                    ) +
                                                                                "..."
                                                                            }
                                                                            {...register(
                                                                                `network_devices.0.config_variables[${i}].value`
                                                                            )}
                                                                        />
                                                                    </Alerttip>
                                                                ) : (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label={con?.name.toUpperCase()}
                                                                        {...register(
                                                                            `network_devices.0.config_variables[${i}].value`
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Card className={classes.card}>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        className={globalStyle.customCardTitle}
                                    >
                                        OVA
                                    </Typography>
                                    <div className={classes.displayFlex}>
                                        <TextField
                                            select
                                            className={
                                                globalStyle.customTextField
                                            }
                                            variant="outlined"
                                            control={controls}
                                            label="OVA"
                                            {...register(
                                                `network_devices.0.ova_filename`
                                            )}
                                            value={
                                                watch(
                                                    `network_devices.0.ova_filename`
                                                ) ||
                                                "" ||
                                                ""
                                            }
                                        >
                                            {ovas?.map((ova) => (
                                                <MenuItem
                                                    value={ova.filename}
                                                    key={ova.filename}
                                                    onClick={() => {
                                                        handleOVAChange(ova);
                                                    }}
                                                >
                                                    {ova.filename}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    {deployOpts?.length > 0 && (
                                        <TextField
                                            select
                                            className={
                                                globalStyle.customTextField
                                            }
                                            variant="outlined"
                                            control={controls}
                                            label="Deployment Option"
                                            {...register(
                                                `network_devices.0.deployment_option`
                                            )}
                                            value={
                                                watch(
                                                    `network_devices.0.deployment_option`
                                                ) || ""
                                            }
                                        >
                                            {deployOpts?.map((dopt) => (
                                                <MenuItem
                                                    value={dopt}
                                                    key={dopt}
                                                >
                                                    {dopt}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    {watch(
                                        `network_devices.0.ova_filename`
                                    ) && (
                                        <>
                                            <Typography
                                                component="h4"
                                                variant="h6"
                                                style={{ marginTop: "10px" }}
                                            >
                                                Port Mapping
                                            </Typography>
                                            {
                                                !["Riverbed SteelHead","Vidterra Compass"].includes(device.name)
                                            && (
                                                <Fab
                                                    variant="extended"
                                                    style={{
                                                        marginTop: "15px",
                                                        marginBottom: "15px",
                                                    }}
                                                    onClick={handleAddFields}
                                                >
                                                    <AddIcon />
                                                    Port
                                                </Fab>
                                            )}

                                            {fields
                                                ?.slice(0, portCounter)
                                                .map((item, id) => (
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        key={item.id}
                                                        className={
                                                            globalStyle.customGridContainer
                                                        }
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                            className={
                                                                globalStyle.customGridItem1
                                                            }
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                className={
                                                                    globalStyle.customTextField
                                                                }
                                                                label="Interface"
                                                                {...register(
                                                                    `network_devices.0.port_mapping[${id}].interface`
                                                                )}
                                                                autoComplete="false"
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                            className={
                                                                globalStyle.customGridItem2
                                                            }
                                                        >
                                                            <TextField
                                                                select
                                                                control={
                                                                    controls
                                                                }
                                                                variant="outlined"
                                                                className={
                                                                    globalStyle.customTextField
                                                                }
                                                                label="Port Group"
                                                                {...register(
                                                                    `network_devices.0.port_mapping[${id}].port_group`
                                                                )}
                                                                value={
                                                                    watch(
                                                                        `network_devices.0.port_mapping[${id}].port_group`
                                                                    ) || ""
                                                                }
                                                            >
                                                                {portGroups?.map(
                                                                    (pg) => (
                                                                        <MenuItem
                                                                            key={
                                                                                pg
                                                                            }
                                                                            value={
                                                                                pg
                                                                            }
                                                                        >
                                                                            {pg}
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </TextField>

                                                            {id ===
                                                                fields.length -
                                                                    1 &&
                                                                fields.length >
                                                                    1 &&
                                                                !DEFAULT_VALUES.includes(
                                                                    fields?.[id]
                                                                        ?.interface
                                                                ) &&
                                                                device.name !==
                                                                    "Riverbed SteelHead" && (
                                                                    <Alerttip title="Remove Interface">
                                                                        <IconButton size="small">
                                                                            <DeleteIcon
                                                                                sx={{
                                                                                    color: "#D3D3D3",
                                                                                }}
                                                                                style={{
                                                                                    marginTop:
                                                                                        "15px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleDelete(
                                                                                        id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </DeleteIcon>
                                                                        </IconButton>
                                                                    </Alerttip>
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </>
                                    )}
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            )}
            <DevTool control={controls} />
        </Container>
    );
};

export default NetworkConfig;
