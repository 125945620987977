import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        '@media (max-width: 450px)': {
            width: '100%'
        },
    },

    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: theme.spacing(2),
    },
    buildbtn: {
        margin: '30px 0',
        padding: "10px",
        width: '10vw',
        backgroundColor: '#EA2331',
        color: '#333333',

        "&:hover": {
            backgroundColor: '#333333',
            color: '#EA2331',
        }
    },
    kitID: {
        alignSelf: "center",
        width: "20%",
        padding: "1%",
        margin: "5px 0"
    }
}));
