import { Button, CardContent, CardHeader } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Context } from "../../../context/Context";

import {
    Card,
    Container,
    Fab,
    IconButton,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    Typography,
    Modal,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { idea } from "react-syntax-highlighter/dist/esm/styles/hljs";
import useStyles from "./styles";

const NetworkConfig = ({
    info,
    controls,
    register,
    unregister,
    getValues,
    setValue,
    watch,
}) => {
    const [allTemplates, setAllTemplates] = useState([]);
    const [selections, setSelections] = useState({});
    const [serialPorts, setSerialPorts] = useState();
    const [showConfigVars, setShowConfigVars] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [conName, setConName] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [groupedLines, setGroupedLines] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState();
    const [hoveredLabelIndex, setHoveredLabelIndex] = useState(null);
    const token = useContext(Context);
    const classes = useStyles();

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));
    const idRef = useRef(2);
    const [selectIds, setSelectIds] = useState([1]);

    let getSerialDevices = "";
    let getSerialPorts = "";
    if (process.env.NODE_ENV === "development") {
        getSerialDevices = "http://localhost:80/api/serial-devices/templates";
        getSerialPorts = "http://localhost:80/api/serial-ports/serial_ports";
    } else if (process.env.NODE_ENV === "production") {
        getSerialDevices = "/api/serial-devices/templates";
        getSerialPorts = "/api/serial-ports/serial_ports";
    }

    const { fields, append, update, remove } = useFieldArray({
        control: controls,
        name: "network_devices",
    });

    const handleAddFields = () => {
        append({
            template_name: "",
            port: "",
        });
    };

    const handleDelete = (index) => {
        remove(index);
    };

    const handleUpdateVariables = async (index, tag) => {
        unregister(`network_devices[${index}].config_variables`);
        unregister(`network_devices[${index}].device_name`);
    };

    const getDevices = async () => {
        await fetch(getSerialDevices, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((devicedata) => {
                const templatesByName = devicedata.templates.reduce(
                    (acc, data) => {
                        let {
                            config_interfaces,
                            device_name,
                            template_name,
                            file_name,
                            config_template,
                            config_variables,
                        } = data;
                        return {
                            ...acc,
                            [device_name]: [
                                ...(acc[device_name] || []),
                                {
                                    config_interfaces: config_interfaces,
                                    device_name: device_name,
                                    template_name: template_name,
                                    file_name: file_name,
                                    config_template: config_template,
                                    config_variables: config_variables,
                                },
                            ],
                        };
                    },
                    {}
                );
                let listByName = Object.entries(templatesByName);
                let groupedTemplates = [];
                for (const template of listByName) {
                    groupedTemplates.push({
                        device_name: template[0],
                        template: template[1],
                    });
                }
                setAllTemplates(groupedTemplates);
            })
            .catch((error) => {
                alert("Error Retrieving Templates", error);
            });
    };

    const renderByName = (template, id, i) => {
        const items = template?.template?.map((p, j) => {
            return (
                <MenuItem
                    onClick={() => {
                        setSelections((currentValue) => {
                            return {
                                ...currentValue,
                                [id]: allTemplates[i].template[j],
                            };
                        });
                        handleUpdateVariables(id);
                        setValue(
                            `network_devices.${id}.device_name`,
                            allTemplates[i].device_name
                        );
                    }}
                    value={p.template_name}
                    key={p.template_name - i}
                >
                    {p.template_name}
                </MenuItem>
            );
        });
        return [<ListSubheader>{template.device_name}</ListSubheader>, items];
    };

    const getAllSerialPorts = async () => {
        await fetch(getSerialPorts, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((serialData) => {
                setSerialPorts(serialData.serial_ports);
            })
            .catch((error) => {
                console("");
            });
    };

    const handleVariableHelpModal = (conName, selectedTemplate) => {
        setCurrentTemplate(selectedTemplate);
        setConName(conName);
        setModalOpen(!modalOpen);
    };

    const findVariableLines = () => {
        const newGroupedLines = [];
        const lines = currentTemplate.split("\r\n");
        let count = 0;

        for (let index = 0; index < lines.length; index++) {
            if (lines[index].includes(conName)) {
                count++;
                let startIndex = index;
                let endIndex = index;

                // Search backwards to find the start index (preceding '!')
                // and limit to 3 lines above
                for (let i = 0; i < 3; i++) {
                    if (startIndex === 0 || lines[startIndex - 1] === "!") {
                        break;
                    }
                    startIndex--;
                }

                // Search forwards to find the end index (following '!')
                // and limit to 3 lines below
                for (let i = 0; i < 3; i++) {
                    if (
                        endIndex === lines.length - 1 ||
                        lines[endIndex + 1] === "!"
                    ) {
                        break;
                    }
                    endIndex++;
                }

                // Extract the block of lines
                const block = lines.slice(startIndex, endIndex + 1);
                const countLine = `>>>>>>>>>>     Instance ${count}:     <<<<<<<<<<\r\n`;
                const joinedString =
                    countLine + block.join("\r\n") + "\r\n" + "\r\n";
                newGroupedLines.push(joinedString);
            }
        }
        setGroupedLines(newGroupedLines);
    };

    const highlightConName = (line) => {
        const parts = line.split(conName);
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < parts.length - 1 && (
                    <span style={{ color: "red" }}>{conName}</span>
                )}
            </React.Fragment>
        ));
    };

    const variableHelper = () => {
        return groupedLines.map((instance, instanceIndex) => (
            <div key={instanceIndex}>
                {instance.split("\r\n").map((line, lineIndex) => {
                    // check for instance line pattern
                    const isInstanceLine =
                        line.startsWith(">>>>>") && line.endsWith("<<<<<");

                    // apply styles to lines conditionally
                    const lineStyle = isInstanceLine
                        ? {
                              whiteSpace: "pre-wrap",
                              color: "#39FF14",
                              textAlign: "center",
                          }
                        : { whiteSpace: "pre-wrap" };

                    return (
                        <div
                            key={`${instanceIndex}-${lineIndex}`}
                            style={lineStyle}
                        >
                            {highlightConName(line)}
                        </div>
                    );
                })}
                {instanceIndex !== groupedLines.length - 1 && (
                    <hr style={{ width: "100%" }} />
                )}
            </div>
        ));
    };

    const handleMouseEnterLabel = (i) => {
        setHoveredLabelIndex(i);
    };
    const handleMouseLeaveLabel = () => {
        setHoveredLabelIndex(null);
    };

    useEffect(() => {
        getDevices();
        getAllSerialPorts();
        if (currentTemplate) {
            findVariableLines();
        }
        setCurrentPage(0);
    }, [conName, currentTemplate]);

    const uniquePort = [];

    serialPorts?.map((p) =>
        p.enabled === true ? uniquePort.push(p.port) : ""
    );
    info?.map((port) => uniquePort.push(port.port));

    const handleShowConfigVars = (e) => {
        e.preventDefault();
        setShowConfigVars(!showConfigVars);
    };

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
            component="main"
            maxWidth="xl"
        >
            <Typography component="h1" variant="h5">
                Build a New Network
            </Typography>

            <Fab
                variant="extended"
                style={{ marginTop: "15px", alignSelf: "center" }}
                onClick={handleAddFields}
            >
                <AddIcon />
                Add a Device
            </Fab>

            <form autoComplete="off" className={classes.form}>
                {fields?.map((item, index) => {
                    return (
                        <Card
                            key={item.id}
                            className={classes.card}
                            style={{ marginBottom: "30px", width: "100%" }}
                            spacing={2}
                            name="network_devices"
                            id={item.id}
                        >
                            <div className={classes.displayFlex}>
                                <div className={classes.verticalAlignDivParent}>
                                    <div className={classes.displayFlex}>
                                        <InputLabel
                                            className={classes.customInputLabel}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Controller
                                            name={`network_devices.${index}.template_name`}
                                            control={controls}
                                            defaultValue={
                                                info?.network_devices ===
                                                undefined
                                                    ? ""
                                                    : info?.network_devices[
                                                          index
                                                      ]?.template_name
                                            }
                                            render={({ field }) => (
                                                <Select {...field}>
                                                    {allTemplates?.map(
                                                        (template, i) =>
                                                            renderByName(
                                                                template,
                                                                index,
                                                                i
                                                            )
                                                    )}
                                                </Select>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.displayFlex}>
                                        <InputLabel
                                            className={classes.customInputLabel}
                                        >
                                            Port:
                                        </InputLabel>
                                        <Controller
                                            style={{ marginLeft: "45px" }}
                                            control={controls}
                                            name={`network_devices[${index}].port`}
                                            render={({ field }) => (
                                                <Select {...field}>
                                                    {uniquePort?.map((port) => (
                                                        <MenuItem
                                                            onChange={
                                                                field.onChange
                                                            }
                                                            key={port}
                                                            value={port}
                                                        >
                                                            {port}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.displayFlex}>
                                        <InputLabel
                                            className={classes.customInputLabel}
                                        >
                                            Device Name:{" "}
                                            {watch(
                                                `network_devices[${index}].device_name`
                                            ) || "Not selected"}
                                        </InputLabel>
                                    </div>
                                    <Button
                                        style={{
                                            fontWeight: "bolder",
                                            letterSpacing: "3px",
                                            width: "200px",
                                            margin: "15px 33%",
                                        }}
                                        className={classes.button}
                                        onClick={handleShowConfigVars}
                                    >
                                        {showConfigVars
                                            ? "Hide Variables"
                                            : "Show Variables"}
                                    </Button>
                                    <div
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {info === undefined
                                            ? allTemplates.map((dev) => {
                                                  return dev.template.map(
                                                      (template) => {
                                                          if (
                                                              template.template_name ===
                                                              watch(
                                                                  `network_devices[${index}].template_name`
                                                              )
                                                          ) {
                                                              return (
                                                                  <>
                                                                      {template?.config_variables?.map(
                                                                          (
                                                                              con,
                                                                              i
                                                                          ) => {
                                                                              const isHovered =
                                                                                  i ===
                                                                                  hoveredLabelIndex;
                                                                              const labelStyle =
                                                                                  {
                                                                                      display:
                                                                                          "block",
                                                                                      maxWidth:
                                                                                          "195px",
                                                                                      height: "38px",
                                                                                      overflowWrap:
                                                                                          "break-word",
                                                                                      cursor: "pointer",
                                                                                      fontWeight:
                                                                                          isHovered
                                                                                              ? "bold"
                                                                                              : "normal",
                                                                                  };
                                                                              return (
                                                                                  <div
                                                                                      key={
                                                                                          i
                                                                                      }
                                                                                      style={{
                                                                                          display:
                                                                                              showConfigVars
                                                                                                  ? "inline-flex"
                                                                                                  : "none",
                                                                                          margin: "20px 0",
                                                                                          width: "230px",
                                                                                      }}
                                                                                  >
                                                                                      <div
                                                                                          style={{
                                                                                              display:
                                                                                                  "none",
                                                                                          }}
                                                                                      >
                                                                                          <TextField
                                                                                              variant="outlined"
                                                                                              value={
                                                                                                  con?.name
                                                                                              }
                                                                                              {...register(
                                                                                                  `network_devices[${index}].config_variables[${i}].name`
                                                                                              )}
                                                                                          />
                                                                                      </div>
                                                                                      <div
                                                                                          style={{
                                                                                              display:
                                                                                                  "inline",
                                                                                          }}
                                                                                      >
                                                                                          <label
                                                                                              style={
                                                                                                  labelStyle
                                                                                              }
                                                                                              onMouseEnter={() =>
                                                                                                  handleMouseEnterLabel(
                                                                                                      i
                                                                                                  )
                                                                                              }
                                                                                              onMouseLeave={
                                                                                                  handleMouseLeaveLabel
                                                                                              }
                                                                                              onClick={() =>
                                                                                                  handleVariableHelpModal(
                                                                                                      con.name,
                                                                                                      template.config_template
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              {con.name.toUpperCase()}
                                                                                          </label>

                                                                                          <TextField
                                                                                              variant="outlined"
                                                                                              {...register(
                                                                                                  `network_devices[${index}].config_variables[${i}].value`
                                                                                              )}
                                                                                          />
                                                                                      </div>
                                                                                  </div>
                                                                              );
                                                                          }
                                                                      )}
                                                                      <Modal
                                                                          open={
                                                                              modalOpen
                                                                          }
                                                                          onClose={() =>
                                                                              setModalOpen(
                                                                                  false
                                                                              )
                                                                          }
                                                                          aria-labelledby="modal-title"
                                                                          aria-describedby="modal-description"
                                                                          style={{
                                                                              display:
                                                                                  "flex",
                                                                              alignItems:
                                                                                  "center",
                                                                              justifyContent:
                                                                                  "center",
                                                                          }}
                                                                      >
                                                                          <Card
                                                                              style={{
                                                                                  maxWidth:
                                                                                      "75%",
                                                                                  minWidth:
                                                                                      "25%",
                                                                                  maxHeight:
                                                                                      "75%",
                                                                                  minHeight:
                                                                                      "15%",
                                                                                  overflowY:
                                                                                      "auto",
                                                                                  boxShadow:
                                                                                      "0 2px 10px black",
                                                                                  border: "2px black solid",
                                                                              }}
                                                                          >
                                                                              <CardHeader
                                                                                  title={
                                                                                      conName
                                                                                  }
                                                                                  style={{
                                                                                      paddingTop:
                                                                                          "0px",
                                                                                      paddingBottom:
                                                                                          "10px",
                                                                                      backgroundColor:
                                                                                          "#800002",
                                                                                      color: "white",
                                                                                      borderBottom:
                                                                                          "2px black solid",
                                                                                      textAlign:
                                                                                          "center",
                                                                                  }}
                                                                              />
                                                                              <CardContent
                                                                                  style={{
                                                                                      padding:
                                                                                          "8px",
                                                                                      margin: "2px",
                                                                                      backgroundColor:
                                                                                          "black",
                                                                                      color: "white",
                                                                                  }}
                                                                              >
                                                                                  {variableHelper()}
                                                                              </CardContent>
                                                                          </Card>
                                                                      </Modal>
                                                                  </>
                                                              );
                                                          }
                                                      }
                                                  );
                                              })
                                            : info?.[0].config_vars?.map(
                                                  //this is for from previous?
                                                  (v, i) => (
                                                      <div
                                                          key={i}
                                                          style={{
                                                              display: "flex",
                                                              margin: "20px 0",
                                                              width: "100%",
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      "none",
                                                              }}
                                                          >
                                                              <TextField
                                                                  variant="outlined"
                                                                  value={Object.keys(
                                                                      v
                                                                  )}
                                                                  {...register(
                                                                      `network_devices[${index}].config_variables[${i}].name`
                                                                  )}
                                                              />
                                                          </div>
                                                          <div
                                                              style={{
                                                                  display:
                                                                      "flex",
                                                                  justifyContent:
                                                                      "center",
                                                                  alignItems:
                                                                      "center",
                                                                  width: "50%",
                                                              }}
                                                          >
                                                              <label
                                                                  style={{
                                                                      display:
                                                                          "block",
                                                                      maxWidth:
                                                                          "195px",
                                                                      height: "38px",
                                                                      overflow:
                                                                          "break-word",
                                                                  }}
                                                              >
                                                                  {Object.keys(
                                                                      v
                                                                  )}
                                                              </label>
                                                          </div>
                                                          <div
                                                              style={{
                                                                  width: "30%",
                                                              }}
                                                          >
                                                              <TextField
                                                                  defaultValue={Object.values(
                                                                      v
                                                                  )}
                                                                  variant="outlined"
                                                                  {...register(
                                                                      `network_devices[${index}].config_variables[${i}].value`
                                                                  )}
                                                              />
                                                          </div>
                                                      </div>
                                                  )
                                              )}
                                    </div>
                                </div>
                                <div className={classes.verticalAlignDivParent}>
                                    <Alerttip title="Remove Device">
                                        <IconButton
                                            size="small"
                                            style={{ marginLeft: "10px" }}
                                        >
                                            <DeleteIcon
                                                sx={{ color: "#D3D3D3" }}
                                                onClick={() => {
                                                    handleDelete(index);
                                                }}
                                            >
                                                Delete
                                            </DeleteIcon>
                                        </IconButton>
                                    </Alerttip>
                                </div>
                            </div>
                        </Card>
                    );
                })}
            </form>
        </Container>
    );
};

export default NetworkConfig;
