import {
    Grid,
    Button,
    Fab,
    Card,
    MenuItem,
    Typography,
    TextField,
    Box,
    IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { DevTool } from "@hookform/devtools";
import AddIcon from "@material-ui/icons/Add";
import Slide from "@mui/material/Slide";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Context } from "../../../context/Context";
import useStyles from "./styles";
import useGlobalStyle from "../../../themes/globalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

const baudrates = [9600, 19200, 28800, 38400, 57600, 76800, 115200];

const WriteErase = () => {
    const [ports, setPorts] = useState();
    const [selPorts, setSelPorts] = useState([]);
    const [selBaudrates, setSelBaudrates] = useState([]);
    const history = useHistory();
    const globalStyle = useGlobalStyle();

    const { control, register, handleSubmit } = useForm({
        defaultValues: {
            writeEraseData: [
                {
                    port: "",
                    baudrate: 9600,
                    username: "",
                    password: "",
                    enable_password: "",
                },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "writeEraseData", // unique name for your Field Array
    });
    let port = [];
    ports?.map((p) => (p.enabled === true ? port.push(p?.port) : ""));
    const uniquePort = [...new Set(port)];

    const classes = useStyles();
    const token = useContext(Context);

    let baseURL = "";
    let portUrl = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/network-utils/validate-workflow";
        portUrl = "http://localhost:80/api/serial-ports/serial_ports";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/network-utils/validate-workflow";
        portUrl = "api/serial-ports/serial_ports";
    }

    const handleAddFields = () => {
        append({
            port: [],
            username: "",
            password: "",
            enable_password: "",
            baudrate: 9600,
        });
    };

    const handleRemoveFields = (index) => {
        remove(index);
    };

    const getPorts = async () => {
        await fetch(portUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setPorts(resdata.serial_ports);
            })
            .catch((error) => {
                alert("Error Retrieving Ports", error);
            });
    };

    const submitWriteErase = async (data) => {
        console.log(data.writeEraseData);

        await fetch(baseURL + "?type=write-erase", {
            method: "POST",
            body: JSON.stringify(data.writeEraseData),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: data.writeEraseData,
                        resdata: resdata,
                    },
                });
            })
            .catch((error) => {
                alert("error", error);
            });
    };
    useEffect(() => {
        getPorts();
    }, []);

    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "#EAEAEA",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(submitWriteErase)}
                    className={classes.form}
                    style={{ textAlign: "center" }}
                >
                    <Button type="submit" className={globalStyle.buildButtons}>
                        Build
                    </Button>
                    <Typography
                        component="h1"
                        variant="h5"
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        Write Erase Network Utility
                    </Typography>
                    <Fab
                        variant="extended"
                        style={{ margin: "15px 0px", alignSelf: "center" }}
                        onClick={handleAddFields}
                    >
                        <AddIcon />
                        Add a Device
                    </Fab>
                    {fields.map((item, index) => (
                        <Card
                            key={item.id}
                            className={classes.card}
                            spacing={2}
                            name="writeEraseData"
                            style={{ marginBottom: "30px", width: "25%" }}
                        >
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={10}>
                                    <div className={classes.divGrid}>
                                        <TextField
                                            select
                                            className={classes.customTextField}
                                            variant="outlined"
                                            control={control}
                                            label="Port"
                                            {...register(
                                                `writeEraseData.${index}.port`
                                            )}
                                            value={selPorts?.[index]}
                                        >
                                            {uniquePort?.sort().map((port) => (
                                                <MenuItem
                                                    onClick={(e) => {
                                                        let newArr = [
                                                            ...selPorts,
                                                        ];
                                                        newArr[index] = port;
                                                        setSelPorts(newArr);
                                                    }}
                                                    value={port}
                                                    key={index}
                                                >
                                                    {port}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            className={classes.customTextField}
                                            variant="outlined"
                                            control={control}
                                            label="Baudrate"
                                            defaultValue={9600}
                                            {...register(
                                                `writeEraseData.${index}.baudrate`
                                            )}
                                            value={selBaudrates?.[index]}
                                        >
                                            {baudrates.map(
                                                (speed, speedIndex) => (
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            let newArr = [
                                                                ...selBaudrates,
                                                            ];
                                                            newArr[index] =
                                                                speed;
                                                            setSelPorts(newArr);
                                                        }}
                                                        key={speedIndex}
                                                        value={speed}
                                                    >
                                                        {speed}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    </div>
                                    <div className={classes.divGrid}>
                                        <TextField
                                            variant="outlined"
                                            className={classes.customTextField}
                                            label="Username"
                                            {...register(
                                                `writeEraseData.${index}.username`
                                            )}
                                            autoComplete="false"
                                        />
                                        <TextField
                                            variant="outlined"
                                            className={classes.customTextField}
                                            label="Password"
                                            {...register(
                                                `writeEraseData.${index}.password`
                                            )}
                                            autoComplete="false"
                                        />
                                        <TextField
                                            variant="outlined"
                                            className={classes.customTextField}
                                            label="Enable password"
                                            {...register(
                                                `writeEraseData.${index}.enable_password`
                                            )}
                                            autoComplete="false"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        onClick={() =>
                                            handleRemoveFields(index)
                                        }
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </form>

                <br />
            </div>
            <DevTool control={control} />
        </Box>
    );
};

export default WriteErase;
