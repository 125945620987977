import {
	Card,
	Container,
	Fab,
	Grid,
	IconButton,
	InputLabel,
	ListSubheader,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@material-ui/icons/Add";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Context } from "../../../context/Context";
import useStyles from "./styles";

const Bios = ({ info, controls, register, unregister, type, getValues, watch }) => {
	const [bios, setBios] = useState([]);
	const [serialPorts, setSerialPorts] = useState();
	const token = useContext(Context);
	const classes = useStyles();

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));

	const allBios = info?.bios_devices;

	const { fields, append, update, remove } = useFieldArray({
		control: controls,
		name: "bios_devices",
	});

	const handleAddFields = () => {
		append({
			tag: [], port: [],
		});
	};

	let baseURL = "";
	let getSerialPorts = "";
	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/bios/bios-devices";
		getSerialPorts = "http://localhost:80/api/serial-ports/serial_ports";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/bios/bios-devices";
		getSerialPorts = "/api/serial-ports/serial_ports";
	}

	const getBIOS = async () => {
		await fetch(baseURL, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((biosData) => {
				const biosByVendor = biosData.bios.reduce((acc, data) => {
					let { name, vendor, tag } = data;
					return { ...acc, [vendor]: [...(acc[vendor] || []), { name: name, tag: tag }] };
				}, {});
				let byVendorList = Object.entries(biosByVendor)
				setBios(byVendorList)
			})
			.catch((error) => {
				alert("Error Retrieving BIOS", error);
			});
	};

	const getAllSerialPorts = async () => {
		await fetch(getSerialPorts, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((serialData) => {
				setSerialPorts(serialData.serial_ports);
			})
			.catch((error) => {
				alert("Error Retrieving Ports", error);
			});
	};

	let tag = [];
	for (const bio of bios) {
		if (bio[1][0].tag === "hpe_el8000_cm" & type === "virtualNetwork") {
			continue;
		}
		tag.push({ "vendor": bio[0], "device": bio[1] })
	}
	allBios?.map((b) => tag.push(b?.tag));

	const uniqueTag = [...new Set(tag)];

	const handleUpdateDeviceFields = async (index, tag) => {
		update(index, {
			tag: tag,
		});
		if (watch(`bios_devices.${index}.tag`) !== "hpe_el8000_cm") {
			unregister(`bios_devices.${index}.cm_mgmt_ip`)
			unregister(`bios_devices.${index}.hpe_ilo_esxi1_ip`)
			unregister(`bios_devices.${index}.netmask`)
			unregister(`bios_devices.${index}.gateway`)
			unregister(`bios_devices.${index}.username`)
			unregister(`bios_devices.${index}.password`)
		}
	}

	const renderByGroup = (product, index) => {
		const items = product?.device?.map(p => {
			return (
				<MenuItem
					value={p.tag}
					onClick={() => {
						handleUpdateDeviceFields(index, p.tag)
					}}
				>
					{p.name}
				</MenuItem>
			)
		})
		return [<ListSubheader>{product.vendor}</ListSubheader>, items]
	}

	const handleDelete = (index) => {
		remove(index)
	}


	let port = [];

	allBios?.map((b) => port.push(b?.port));

	serialPorts?.map((p) => (p.enabled === true ? port.push(p?.port) : ""));

	const uniquePort = [...new Set(port)];

	useEffect(() => {
		getBIOS();
		getAllSerialPorts();
	}, []);

	return (
		<Container component="main" maxWidth="xl">
			<Paper className={classes.paper} elevation={6}>
				<Typography component="h1" variant="h5">
					BIOS Devices
				</Typography>
				<Fab
					variant="extended"
					onClick={handleAddFields}
					style={
						window.location.pathname != "/virtualnetwork" //warning here?
							? { marginTop: 15 }
							: { display: "none" }
					}
				>
					<AddIcon />
					Devices
				</Fab>
				<form autoComplete="off" className={classes.form}>
					{fields?.map((item, index) => (

						<Card
							key={item.id}
							className={classes.card}
							spacing={2}
							name="bios_devices"
							style={{ display: "flex", margin: "2px" }}
						>
							<div style={{ display: "flex", flexDirection: "row", margin: "10px" }} >
								<div style={{ display: "flex", margin: "10px", marginRight: "20px" }}>
									<InputLabel style={{
										color: "black",
										display: "flex",
										margin: "10px 10px",
									}}>
										Device:
									</InputLabel>
									<Controller
										name={`bios_devices.${index}.tag`}
										control={controls}
										defaultValue={
											info?.bios_devices === undefined
												? ""
												: info?.bios_devices[index]?.tag
										}
										render={({ field }) => (
											<Select {...field}
												className={classes.select}
												defaultValue=''
											>
												{uniqueTag
													?.sort()
													?.map(p => renderByGroup(p, index))}
											</Select>
										)}
									/>
								</div>
								<div style={{ display: "flex", margin: "10px" }}>
									<InputLabel
										style={{
											color: "black",
											display: "flex",
											margin: "10px 10px",
										}}
									>
										Port:
									</InputLabel>
									<Controller
										control={controls}
										name={`bios_devices.${index}.port`}
										defaultValue={
											info?.bios_devices === undefined
												? ""
												: info?.bios_devices[index]?.port
										}
										render={({ field }) => (
											<Select {...field} className={classes.select} defaultValue=''>
												{uniquePort?.map((port, index) => (
													<MenuItem
														onChange={field.onChange}
														key={index}
														value={port}
													>
														{port}
													</MenuItem>
												))}
											</Select>
										)}
									/>
									<div>
										{fields.length > 1 ?
											<Alerttip title="Remove Device">
												<IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
													<DeleteIcon
														sx={{ color: "#D3D3D3" }}
														onClick={() => {
															handleDelete(index);
														}}
													>
														Delete
													</DeleteIcon>
												</IconButton>
											</Alerttip>
											: ""}
									</div>
								</div>
							</div>
							{watch(`bios_devices[${index}].tag`) === "hpe_el8000_cm" ?
								<Grid container spacing={1}
									justifyContent="center"
									alignItems="center"
									align="center">
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the Management IP address. (Example: 192.168.1.30)">
											<TextField
												className={classes.textField}
												variant="outlined"
												size="small"
												label="CM ILO IP"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.cm_mgmt_ip
												}
												{...register(`bios_devices.${index}.cm_mgmt_ip`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the starting blade IP. (Example: 192.168.1.1)">
											<TextField
												className={classes.textField}
												variant="outlined"
												size="small"
												label="Blade 1 ILO IP"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.hpe_ilo_esxi1_ip
												}
												{...register(`bios_devices.${index}.hpe_ilo_esxi1_ip`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the Subnet Mask. (Example: 255.255.255.0)">
											<TextField
												className={classes.textField}
												variant="outlined"
												size="small"
												label="NETMASK"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.netmask
												}
												{...register(`bios_devices.${index}.netmask`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the Gateway. (Example: 192.168.1.1)">
											<TextField
												className={classes.textField}
												variant="outlined"
												size="small"
												label="GATEWAY"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.gateway
												}
												{...register(`bios_devices.${index}.gateway`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the Device Username.">
											<TextField
												className={classes.textField}
												variant="outlined"
												size="small"
												label="USERNAME"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.username
												}
												{...register(`bios_devices.${index}.username`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<Alerttip title="Enter the Device Password.">
											<TextField
												className={classes.textField}
												variant="outlined"
												type="password"
												size="small"
												label="PASSWORD"
												defaultValue={
													info?.bios_devices === undefined
														? ""
														: info?.bios_devices[index]?.password
												}
												{...register(`bios_devices.${index}.password`,
												)}
											>
											</TextField>
										</Alerttip>
									</Grid>
								</Grid>
								: ""}
						</Card>
					))}
				</form>
			</Paper>
		</Container>
	);
};

export default Bios;
