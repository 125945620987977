import React, { useState, useContext, useRef, useEffect } from "react";
import { Context } from "../../../context/Context";
import { Controller } from "react-hook-form";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import {
	Typography,
	Container,
	Card,
	Fab,
	Select,
	MenuItem,
	InputLabel,
	TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./styles";

const NetworkConfigPrevious = ({ register, control, info }) => {
	const [allTemplates, setAllTemplates] = useState();
	const [selections, setSelections] = useState({});
	const [serialPorts, setSerialPorts] = useState();
	const [showConfigVars, setShowConfigVars] = useState(true);
	const token = useContext(Context);
	const classes = useStyles();

	const location = useLocation();
	const [currentBuild, setCurrentBuild] = useState({});

	const idRef = useRef(2);
	const initValue = location.state.files.serial_devices;
	const initDevices = initValue.length;
	const totalDevices = initDevices;
	const [selectIds, setSelectIds] = useState([0]);

	const buildID = location?.state?.files.build_id;
	const prevBuild = location?.state?.files;
	const getCurrentBuild = () => {
		fetch(getBuildById + "/" + buildID, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((bld) => {
				setCurrentBuild(bld);
			})
			.catch((error) => {
				alert("Error Retrieving Network Builds", error);
			});
	};

	let getSerialDevices = "";
	let getSerialPorts = "";
	let getBuildById = "";
	if (process.env.NODE_ENV === "development") {
		getSerialDevices = "http://localhost:80/api/serial-devices/templates";
		getSerialPorts = "http://localhost:80/api/serial-ports/serial_ports";
		getBuildById = "http://localhost:80/api/network-builds";
	} else if (process.env.NODE_ENV === "production") {
		getSerialDevices = "/api/serial-devices/templates";
		getSerialPorts = "/api/serial-ports/serial_ports";
		getBuildById = "/api/network-builds";
	}

	const getDevices = async () => {
		await fetch(getSerialDevices, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((devicedata) => {
				setAllTemplates(devicedata.templates);
			})
			.catch((error) => {
				console.log("");
			});
	};

	const getAllSerialPorts = async () => {
		await fetch(getSerialPorts, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((serialData) => {
				setSerialPorts(serialData.serial_ports);
			})
			.catch((error) => {
				console("");
			});
	};

	useEffect(() => {
		getDevices();
		getAllSerialPorts();
		getCurrentBuild();
		for (let i = 1; i < totalDevices; ++i) {
			const btn = document.getElementById('moreDevices');
			btn.click();
		}

	}, []);

	const uniquePort = [];

	serialPorts?.map((p) => (p.enabled === true ? uniquePort.push(p.port) : ""));
	info?.map((port) => uniquePort.push(port.port));

	const handleShowConfigVars = (e) => {
		e.preventDefault();
		setShowConfigVars(!showConfigVars);
	};

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
			component="main"
			maxWidth="xl"
		>
			<Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
				Build from Previous Network<br />
				<span style={{ fontSize: "14px", display: "block" }}>(ID: {buildID})</span>
			</Typography>

			<Fab
				variant="extended"
				style={{ marginTop: "15px", alignSelf: "center", display: "none" }}
				id="moreDevices"
				onClick={() => {
					setSelectIds((currentValue) => {
						return [...currentValue, idRef.current++];
					});
				}}
			>
				<AddIcon />
				More Devices
			</Fab>

			<form autoComplete="off" className={classes.form}>
				{selectIds.map((id, index) => {
					const sel = selections[id];

					return (
						<Card
							key={id}
							className={classes.card}
							style={{ marginBottom: "30px", width: "100%" }}
							spacing={2}
							name="network_devices"
							id={id}
						>
							<InputLabel
								style={{
									marginRight: "10px",
									marginTop: "10px",
								}}
							>
								Template
							</InputLabel>

							<Controller
								name={`network_devices[${index}].template_name`}
								control={control}
								defaultValue={prevBuild.serial_devices[`${index}`].template_name}
								render={({ field }) => (
									<Select {...field}
										onChange={(e) => {
											setSelections((currentValue) => {
												return {
													...currentValue,
													[id]: allTemplates[e.target.value],
												};
											});
										}}
										key={id}
										disabled

									>
										<MenuItem
											value={prevBuild.serial_devices[`${index}`].template_name}
										>
											{prevBuild.serial_devices[`${index}`].template_name}
										</MenuItem>
									</Select>
								)}
							/>

							{!sel ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										alignItems: "center",
									}}
								>
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "column",
											marginLeft: "20%",
										}}
									>
										<div
											style={{
												width: "33%",
												marginBottom: "50px",
												alignSelf: "center",
												display: "flex",
											}}
										>
											<InputLabel
												style={{
													color: "black",
													display: "flex",
													margin: "20px 20px",
												}}
											>
												Select Port
											</InputLabel>
											<Controller
												name={`network_devices[${index}].port`}
												style={{ marginLeft: "45px" }}
												control={control}
												defaultValue={prevBuild.serial_devices[`${index}`].port}
												key={`network_devices[${index}].port`}
												render={({ field }) => (
													<Select {...field} disabled>
														{uniquePort?.map(
															(port) => (
																<MenuItem
																	onChange={
																		field.onChange
																	}
																	key={prevBuild.serial_devices[`${index}`].port}
																	value={prevBuild.serial_devices[`${index}`].port}
																>
																	<span style={{ display: "none" }}>{port}</span>
																	{prevBuild.serial_devices[`${index}`].port}
																</MenuItem>
															)
														)}
													</Select>
												)}
											/>
										</div>
										<Typography
											style={{
												alignSelf: "center",
												marginRight: "22%",
											}}
										>
											Device Name:{" "}
											{prevBuild.serial_devices[`${index}`].device.name || ""}
										</Typography>
										<Button
											style={{ fontWeight: "bolder", letterSpacing: "3px", width: "200px", margin: "15px 33%" }}
											className={classes.button}
											onClick={handleShowConfigVars}
										>
											{showConfigVars
												? "Hide Variables"
												: "Show Variables"}
										</Button>
										<div style={{ display: "none" }}>
											<TextField
												label="Template name"
												value={prevBuild.serial_devices[`${index}`].template_name}
												{...register(
													`network_devices[${index}].template_name`
												)}
											/>
											<TextField
												label="device name"
												value={prevBuild.serial_devices[`${index}`].device.name}
												{...register(
													`network_devices[${index}].device_name`
												)}
											/>
										</div>

										<div style={{ width: "95%", marginLeft: "-40px" }}>
											{info === undefined
												? prevBuild.serial_devices[`${index}`].config_variables?.map(
													(con, i) => {
														return (
															<div
																key={i}
																style={{
																	display:
																		showConfigVars
																			? "inline-flex"
																			: "none",
																	margin: "20px 0",
																	width: "230px",
																}}
															>
																<div
																	style={{
																		display:
																			"none",
																	}}
																>
																	<TextField
																		variant="outlined"
																		value={
																			con?.name
																		}
																		{...register(
																			`network_devices[${index}].config_variables[${i}].name`
																		)}
																	/>
																</div>
																<div
																	style={{
																		display:
																			"inline",
																	}}
																>
																	<label style={{ overflowWrap: "break-word", display: "block", maxWidth: "195px", height: "38px" }}>
																		{con.name.toUpperCase()}
																	</label>

																	<Controller
																		control={control}
																		name={prevBuild.serial_devices[`${index}`].config_variables[`${index}`].value}
																		render={({ field }) => (
																			<TextField {...field}
																				defaultValue={con.value}
																				variant="outlined"
																				{...register(
																					`network_devices[${index}].config_variables[${i}].value`
																				)}
																			/>
																		)}
																	/>
																</div>
															</div>
														);
													}
												)
												: info[`${index}`].serial_devices[`${index}`].config_variables.map(
													(v, i) => (
														<div
															key={i}
															style={{
																display: "flex",
																margin: "20px 0",
																width: "100%",
															}}
														>
															<div
																style={{
																	display:
																		"none",
																}}
															>
																<TextField
																	variant="outlined"
																	value={Object.keys(
																		v
																	)}
																	{...register(
																		`network_devices[${index}].config_variables[${i}].name`
																	)}
																/>
															</div>
															<div
																style={{
																	display:
																		"flex",
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																	width: "50%",
																}}
															>
																<label style={{ display: "block", maxWidth: "195px", height: "38px", overflow: "break-word" }}>
																	{Object.keys(
																		v
																	)}
																</label>
															</div>
															<div
																style={{
																	width: "30%",
																}}
															>
																<TextField
																	defaultValue={Object.values(
																		v
																	)}
																	variant="outlined"
																	{...register(
																		`network_devices[${index}].config_variables[${i}].value`
																	)}
																/>
															</div>
														</div>
													)
												)}
										</div>
									</div></div>
							) : (
								""
							)}
						</Card>
					);
				})}
			</form>
		</Container>
	);
};

export default NetworkConfigPrevious;
