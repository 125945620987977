import {
    Box,
    Button,
    Container,
    FormControlLabel,
    FormGroup,
    Paper,
    Tab,
    TextField,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../../context/Context";
import { useSystemSettingsContext } from "../../../context/SystemSettings";
import ESXIVersion from "../Advanced/ESXIVersion";
import Bios from "../BIOS/Bios";
import AddedVSwitch from "../ISO/AddedVSwitch";
import AddedVmk from "../ISO/AddedVmk";
import AdvOptions from "../ISO/AdvOptions";
import Host from "../ISO/Host";
import Settings from "../ISO/Settings";
import VSwitchVmks from "../ISO/VSwitchVmks";
import NetworkConfig from "../Network/VNetworkConfig";
import VCSAVersion from "../VCSA/VCSAVersion";
import useStyles from "./styles";

const VirtualNetwork = () => {
    const location = useLocation();
    const info = location?.state?.files?.config || location.state?.files;
    const { systemState } = useSystemSettingsContext();
    const classes = useStyles();
    const token = useContext(Context);
    const [showBios, setShowBios] = useState(
        info?.do_bios === undefined ? true : info.do_bios
    );

    const handleShowBios = () => setShowBios(!showBios);

    const [value, setTabValue] = useState("0");

    const handleChange = (e, newValue) => {
        setTabValue(newValue);
    };
    const history = useHistory();

    let getBuildById = "";
    let cancelURL = "";
    let postVirtualNetworkBuildURL = "";
    if (process.env.NODE_ENV === "development") {
        cancelURL = "http://localhost:80/api/control/cancel-build";
        getBuildById = "http://localhost:80/api/virtual-network-builds";
        postVirtualNetworkBuildURL =
            "http://localhost:80/api/virtual-network-builds/validate-workflow";
    } else if (process.env.NODE_ENV === "production") {
        cancelURL = "/api/control/cancel-build";
        getBuildById = "/api/virtual-network-builds";
        postVirtualNetworkBuildURL =
            "/api/virtual-network-builds/validate-workflow";
    }

    const allBios = info?.bios_devices;
    const allHosts = info?.hosts;
    const allAdded = info?.added_vswitches;
    const allVMK = info?.added_vmks;
    const allEsxiAdvOpt = info?.esxi_advanced_options;

    // const allPortMapping = info?.port_mapping;
    const allNetwork = info?.virtual_serial_devices;

    const {
        register,
        unregister,
        control,
        handleSubmit,
        getValues,
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            bios_devices: allBios || [
                {
                    tag: [],
                    port: [],
                },
            ],
            hosts: allHosts || [
                {
                    ip: "",
                    hostname: "",
                    pw: "",
                    user: "",
                },
            ],
            added_vswitches: allAdded ||
                [] || [
                    {
                        name: "",
                        port_groups: [{ name: "", vlan: "" }],
                        uplinks: [],
                        vlan: "",
                    },
                ],
            added_vmks: allVMK ||
                [] || [
                    {
                        name: "",
                        port_group: "",
                        network: "",
                        mask: "",
                        VMotion: "",
                    },
                ],
            additional_vswitch0_uplinks:
                info?.additional_vswitch0_uplinks || [],
            vm_portgroup: info?.vm_portgroup || [],
            vm_portgroup_vlan: info?.vm_portgroup_vlan || [],
            esxi_advanced_options: allEsxiAdvOpt ||
                [] || [{ key: "", value: "" }],
            extra_kickstart: info?.extra_kickstart || "",
            network_devices: allNetwork || [
                {
                    name: "",
                    variant: "",
                    ova_filename: "",
                    port: "",
                    port_mapping: [{ interface: "", port_group: "" }],
                    template_name: "",
                    config_variables: [],
                    deployment_option: "",
                },
            ],
            do_bios: info?.do_bios,
        },
        mode: "onBlur",
        reValidateMode: "onChange",
    });

    const esxiLicense = systemState.licenses.filter(
        (license) => license.license_type === "esxi"
    );

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));

    const submitForm = async (data) => {
        data["virtual_serial_devices"] = data["network_devices"];
        delete data["network_devices"];
        delete data["port_mapping"];
        data.do_pxe = true;
        data.do_csr = true;

        if (data.do_bios === false) {
            delete data["bios_devices"];
        }
        if (data.vlanid === "") {
            delete data["vlanid"];
        }
        if (data.vcsa_dns_0 === "") {
            delete data["vcsa_dns_0"];
        }
        if (data.vcsa_dns_1 === "") {
            delete data["vcsa_dns_1"];
        }
        if (data.vcsa_gateway === "") {
            delete data["vcsa_gateway"];
        }
        if (data.vcsa_ntp_server_0 === "") {
            delete data["vcsa_ntp_server_0"];
        }
        if (data.vcsa_ntp_server_1 === "") {
            delete data["vcsa_ntp_server_1"];
        }
        await fetch(postVirtualNetworkBuildURL, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: data,
                        resdata: resdata,
                        kit_id: data.kit_id,
                    },
                });
            })
            .catch((error) => {
                alert("Error Deploying. Please Try Again.", error);
            });
    };

    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "#EAEAEA",
            }}
        >
            {info ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Alert
                        style={{ marginBottom: "10px", width: "30%" }}
                        severity="warning"
                    >
                        Please Review Each Tab
                    </Alert>
                </div>
            ) : (
                ""
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={handleSubmit(submitForm)}
                    type="submit"
                    className={classes.buildbtn}
                    id="submitVnBuild"
                >
                    Build
                </Button>
                <input
                    className={classes.kitID}
                    placeholder="Kit ID"
                    {...register("kit_id")}
                    defaultValue=""
                    autoComplete="false"
                />
            </div>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} variant="fullWidth">
                        <Tab className={classes.text} label="BIOS" value="0" />
                        <Tab className={classes.text} label="ESXI" value="1" />
                        <Tab className={classes.text} label="OVA" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="0">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup
                            style={{
                                display: "block",
                                marginLeft: "35px",
                                marginBottom: "-45px",
                            }}
                        >
                            <FormControlLabel
                                style={{
                                    backgroundColor: "#cccccc",
                                    height: "50px",
                                    width: "100px",
                                    borderRadius: "5%",
                                }}
                                control={
                                    <input
                                        type="checkbox"
                                        style={{ opacity: 0 }}
                                        onClick={handleShowBios}
                                        checked={showBios}
                                        {...register("do_bios")}
                                    />
                                }
                                label={showBios ? "Disable" : " Enable"}
                            />
                        </FormGroup>
                    </div>
                    {showBios ? (
                        <Bios
                            info={info}
                            controls={control}
                            register={register}
                            unregister={unregister}
                            getValues={getValues}
                            setValue={setTabValue}
                            watch={watch}
                            type={"virtualNetwork"}
                        />
                    ) : (
                        <p style={{ marginBottom: "25px" }}></p>
                    )}
                </TabPanel>

                <TabPanel value="1">
                    <Host
                        control={control}
                        register={register}
                        getValues={getValues}
                        info={info}
                        controls={control}
                        type={"virtualNetwork"}
                    />
                    <ESXIVersion
                        esxiLicense={esxiLicense}
                        control={control}
                        register={register}
                        info={info}
                    />
                    <Settings
                        hide={true}
                        control={control}
                        register={register}
                        info={info}
                    />
                    <VSwitchVmks
                        control={control}
                        register={register}
                        info={info}
                    />
                    <AddedVSwitch
                        controls={control}
                        register={register}
                        getValues={getValues}
                    />
                    <AddedVmk
                        controls={control}
                        register={register}
                        getValues={getValues}
                        info={info}
                    />
                    <AdvOptions
                        controls={control}
                        register={register}
                        getValues={getValues}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <Container component="main" maxWidth="xl">
                        <Paper
                            style={{ display: "flex", flexDirection: "column" }}
                            className={classes.paper}
                            elevation={6}
                        >
                            <NetworkConfig // this is filename: VNetworkConfig.jsx
                                register={register}
                                unregister={unregister}
                                controls={control}
                                info={info}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                            />
                        </Paper>
                    </Container>
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default VirtualNetwork;
